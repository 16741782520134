<template>
  <div class="flex flex-col gap-10 pt-[80px] md:gap-16">
    <BlockImageHeader
      badge="We Move & Remove, You Relax"
      title="THE EASIEST WAY TO MOVE YOUR THINGS." />

    <BlockCustomers id="reviews" />

    <LazyBlockServices id="services" />

    <!-- How it works -->
    <BlockInfo
      badge-text="How it works"
      heading-text="The Picup Difference">
      Join thousands of happy customers across QLD & NSW who've trusted Picup to move them into new homes and clear out unwanted items.
      <template #cards>
        <BlockInfoCard
          icon="heroicons:currency-dollar-solid"
          heading="Simple Fixed Prices"
          background-color="bg-green-50"
          icon-color="text-green-500">
          Get instant online quotes with fixed prices and no hidden surprises.
        </BlockInfoCard>
        <BlockInfoCard
          icon="heroicons:check-badge-solid"
          heading="Fully licensed & insured"
          background-color="bg-green-50"
          icon-color="text-green-500">
          Our PicUp drivers are background checked, certified and fully insured.
        </BlockInfoCard>
        <BlockInfoCard
          icon="heroicons:cursor-arrow-rays-solid"
          heading="On-time & on-demand"
          background-color="bg-green-50"
          icon-color="text-green-500">
          No back and forth. You book and we're there — it's that simple.
        </BlockInfoCard>
      </template>
    </BlockInfo>

    <!-- Business -->
    <BlockInfo
      id="businesses"
      badge-text="Business Deliveries"
      heading-text="Delivering For Business"
      background-color="bg-sky-900">
      Partner with the leading last-mile delivery service for big & bulky items in Sydney, Brisbane, Sunshine Coast, Gold Coast & surrounding areas.
      <template #cta>
        <NuxtLink href="mailto:info@picup.com.au">
          <Button class="bg-sky-500">
            Contact Our Team
          </Button>
        </NuxtLink>
      </template>
      <template #cards>
        <BlockInfoCard
          icon="heroicons:truck-solid"
          heading="Reliable & Fast Delivery"
          background-color="bg-sky-800"
          text-color="text-white"
          icon-color="text-white">
          Our extensive fleet guarantees fast and reliable delivery, that delights your customers.
        </BlockInfoCard>
        <BlockInfoCard
          icon="heroicons:clock-solid"
          heading="Last-mile specialists"
          background-color="bg-sky-800"
          text-color="text-white"
          icon-color="text-white">
          Last-mile, big & bulky item delivery experts. On-demand or scheduled.
        </BlockInfoCard>
        <BlockInfoCard
          icon="heroicons:banknotes-solid"
          heading="Simple online bookings"
          background-color="bg-sky-800"
          text-color="text-white"
          icon-color="text-white">
          Save time and money with instant online quotes and simple fixed-price deliveries.
        </BlockInfoCard>
      </template>
    </BlockInfo>

    <LazyBlockHaulers :show-button="true" id="apply-to-drive" />

    <!-- FAQs -->
    <LazyBlockFaqs id="faqs" />

    <!-- Footer -->
    <BlockFooter />
  </div>
</template>

<script setup lang="ts">
useSeoMeta({
  title: 'PicUp | The Easiest Way To Move Your Things',
  description: 'Picup offer services from small moves, to business deliveries and waste removal. Get an instant quote today!',
  ogImage: '/social-preview.png',
  ogUrl: 'https://picup.com.au',
})
</script>
